// Импортируем createAsyncThunk из Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


// Создаем асинхронный экшн
export const getAllShowersData = createAsyncThunk(
    'main/getAllShowersData',
    async (_, { rejectWithValue }) => {
      try {
        // Использование axios для запроса
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/getAllShowersClient`);
  
        console.log('data = ', response.data);
        return response.data; // Возвращаем полученные данные
      } catch (error) {
        // В axios ошибка содержит объект response
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );
// Создание слайса (если еще не создан)
const mainSlice = createSlice({
  name: 'main',
  initialState: {
    showers: [],
    loading: false,
    error: null,
  },
  reducers: {
    // твои редьюсеры
  },
  extraReducers: {
    [getAllShowersData.pending]: (state) => {
      // Устанавливаем состояние загрузки в true, когда запрос начал выполняться
      
      state.loading = true;
    },
    [getAllShowersData.fulfilled]: (state, action) => {
      // Обновляем состояние showers данными, полученными из запроса
     
      state.showers = action.payload;
      state.loading = false;
    },
    [getAllShowersData.rejected]: (state, action) => {
      // Устанавливаем состояние ошибки, если запрос завершился неудачно
              state.error = action.error.message;
      state.loading = false;
    },
  },
});

export default mainSlice.reducer;
