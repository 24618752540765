import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getAllShowersData } from '../store/slice/mainSlice';
import { fetchCabinPhotos, selectCabinPhotos } from '../store/slice/cabinPhotosSlice';
import { PiGooglePhotosLogoDuotone } from 'react-icons/pi';
import { MdOutlineCalculate } from 'react-icons/md';

const MainPage = () => {
  const dispatch = useDispatch();

  //const [showers, setShowers] = useState([]);

  const [selectedHardwareSetId, setSelectedHardwareSetId] = useState('');
  const [hasPhotos, setHasPhotos] = useState(false);

  const [selectedGlassId, setSelectedGlassId] = useState('');
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [editableDimensions, setEditableDimensions] = useState([]);
  const [editableHeight, setEditableHeight] = useState('');
  const [finalPrice, setFinalPrice] = useState('');
  const [photos, setPhotos] = useState([]);
  const { showers, loading, error } = useSelector((state) => state.main);
  const photosData = useSelector(selectCabinPhotos);
  const [selectedCabinId, setSelectedCabinId] = useState(null); // состояние для хранения выбранной кабины
  const [height, setHeight] = useState('');
  const [dimensions, setDimensions] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [displayPhotos, setDisplayPhotos] = useState([]);

  useEffect(() => {
    dispatch(getAllShowersData());
    dispatch(fetchCabinPhotos(1));
    // Пустой массив зависимостей гарантирует, что действие вызовется только при первом рендеринге
  }, [dispatch]);

  const handleChangeHeight = (e) => {
    setHeight(e.target.value); // Обновление высоты в локальном состоянии
  };

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const handleSelectCabin = (cabinId) => {
    setHasPhotos(false);
    setDisplayPhotos(false);

    console.log('Выбираем другую кабину = ', cabinId);
    const cabin = showers.find((cabin) => cabin.cabin_id === cabinId);
    // Установка выбранной кабины
    setSelectedCabinId(cabinId);

    // Сброс выбранного стекла и фурнитуры в стейте
    setSelectedGlassId('');
    setSelectedHardwareSetId('');

    // Сброс изображения, если используется
    setSelectedImageUrl(cabin ? cabin.img_url : '');

    // Сброс высоты и измерений для редактирования
    setHeight(cabin ? cabin.height.toString() : '');
    setDimensions(cabin ? cabin.dimensions : []);
    setEditableDimensions(
      cabin
        ? cabin.dimensions.map((dim) => ({
            ...dim,
            editedValue: dim.dimension, // или любое другое начальное значение
          }))
        : [],
    );

    // Очистка финальной цены, если она была рассчитана
    setFinalPrice('');
    setSelectedCabinId(cabinId);
    setHeight(cabin ? cabin.height.toString() : ''); // Установка высоты для редактирования
    setDimensions(cabin ? cabin.dimensions : []); // Сохраняем изначальные измерения в состояние
  };

  // Функция для проверки наличия фотографий
  const checkForPhotos = (cabinId, glassId, hardwareSetId) => {
    setDisplayPhotos(false);

    const numCabinId = Number(cabinId);
    const numGlassId = Number(glassId);
    const numHardwareSetId = Number(hardwareSetId);

    const foundCabin = photosData.find((cabin) => cabin.cabin_id === numCabinId);
    const foundSet = foundCabin?.sets.find((set) => set.set_id === numHardwareSetId);
    const foundGlass = foundSet?.glasses.find((glass) => glass.glass_id === numGlassId);
    const photos = foundGlass?.photos || [];

    setHasPhotos(photos.length > 0);
    setSelectedPhotos(photos);
  };

  const handleSelectGlass = (e) => {
    const newSelectedGlassId = e.target.value;
    setSelectedGlassId(newSelectedGlassId);

    // Проверяем, выбрана ли фурнитура перед проверкой фотографий
    if (selectedHardwareSetId) {
      checkForPhotos(selectedCabinId, newSelectedGlassId, selectedHardwareSetId);
    } else {
      // Если фурнитура не выбрана, убеждаемся, что кнопка не отображается
      setHasPhotos(false);
    }
  };

  const handleSelectHardware = (e) => {
    const newSelectedHardwareSetId = e.target.value;
    setSelectedHardwareSetId(newSelectedHardwareSetId);

    // Проверяем, выбрано ли стекло перед проверкой фотографий
    if (selectedGlassId) {
      checkForPhotos(selectedCabinId, selectedGlassId, newSelectedHardwareSetId);
    } else {
      // Если стекло не выбрано, убеждаемся, что кнопка не отображается
      setHasPhotos(false);
    }
  };
  // Находим выбранную кабину по id для получения информации о стеклах
  const selectedCabin = showers.find((cabin) => cabin.cabin_id === selectedCabinId);

  const handleChangeDimension = (index, value) => {
    const updatedDimensions = dimensions.map((dim, i) => {
      if (i === index) {
        return { ...dim, dimension: value };
      }
      return dim;
    });
    setDimensions(updatedDimensions); // Обновляем состояние с измененными измерениями
  };

  function calculate() {
    if (!selectedGlassId) {
      alert('Выберите тип стекла');
      return;
    }
    if (!selectedHardwareSetId) {
      alert('Выберите фурнитуру');
      return;
    }
    // Подготавливаем измерения с updatedValue для отправки
    const dimensionsForServer = dimensions.map((dim) => ({
      ...dim,
      editedValue: dim.dimension, // Предполагаем, что dim.dimension уже содержит измененное значение
    }));

    // Здесь ты можешь использовать dimensionsForServer для отправки данных на сервер

    getCalculate(dimensionsForServer); // Передаем обновленные измерения в функцию запроса
  }

  const getCalculate = async (dimensionsForServer) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/getCalculateClient`, {
        params: {
          selectedCabinId,
          selectedGlassId,
          selectedSetId: selectedHardwareSetId,
          editableDimensions: dimensionsForServer,
          editableHeight: height,
        },
      });
      console.log('response.data >>', response.data);
      const basePrice = response.data.finalPrice;
      const newFinalPrice = calculateFinalPriceWithMargin(basePrice); // добавляем торг наценку магазина
      setFinalPrice(newFinalPrice);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  // Функция для расчета итоговой цены
  const calculateFinalPriceWithMargin = (basePrice) => {
    // Получаем торговую наценку из localStorage
    const tradeMargin = localStorage.getItem('tradeMargin');
    const marginValue = parseFloat(tradeMargin);

    // Проверяем, что наценка положительная и не равна нулю
    if (marginValue > 0) {
      // Рассчитываем итоговую цену с учетом наценки
      return Math.round(basePrice + basePrice * (marginValue / 100));
    }

    // Если наценка отсутствует, нулевая или отрицательная, возвращаем исходную себестоимость
    return Math.round(basePrice);
  };

  // Получаем hardware_sets для выбранной душевой кабины

  const showPhoto = async () => {
    setDisplayPhotos(true);
  };

  // Создаем копию массива showers и сортируем его по полю position
  const sortedShowers = [...showers].sort((a, b) => a.position - b.position);

  return (
    <>
      <Navbar></Navbar>
      <h3>Выберите модификацию</h3>
      <div className="flex flex-wrap justify-center">
        {sortedShowers.map((cabin) => (
          <div key={cabin.cabin_id} className="p-2 w-1/3 sm:w-1/4 md:w-1/6 lg:w-1/12">
            <div
              className={`${
                selectedCabinId === cabin.cabin_id
                  ? 'border-4 border-blue-500'
                  : 'border border-gray-200'
              } rounded-lg cursor-pointer`}
              onClick={() => handleSelectCabin(cabin.cabin_id)}>
              <img
                src={`${process.env.REACT_APP_URL}/${cabin.img_url}`}
                alt={cabin.name}
                className="w-full h-auto object-cover rounded-t-lg"
              />
              <h3 className="mt-2 text-xs font-normal">{cabin.name}</h3>
            </div>
          </div>
        ))}
      </div>

      {selectedCabin && selectedImageUrl && (
        <div className="text-center mt-4">
          <img
            src={`${process.env.REACT_APP_URL}/${selectedImageUrl}`}
            alt="Выбранная кабина"
            className="max-w-full h-auto mx-auto pl-10 pr-10 " // Пример классов Tailwind CSS для стилизации
          />
          <h3 className="mt-2 text-lg font-semibold">{selectedCabin.name}</h3>{' '}
          {/* Отображение названия кабины */}
        </div>
      )}

      {selectedCabin && (
        <div className="mt-4">
          <label htmlFor="glassSelect" className="block mb-2 text-sm font-medium text-gray-900">
            Выберите тип стекла:
          </label>
          <select
            id="glassSelect"
            value={selectedGlassId}
            onChange={handleSelectGlass}
            className="border py-2 rounded-md border-slate-600 mt-4 bg-emerald-100">
            <option value="">Выберите стекло</option>
            {selectedCabin.glasses.map((glass) => (
              <option key={glass.glass_id} value={glass.glass_id}>
                {glass.glass_name} - {glass.glass_price}
              </option>
            ))}
          </select>
        </div>
      )}
      {selectedCabin && selectedCabin.hardware_sets && (
        <div className="mt-4">
          <label htmlFor="hardwareSelect" className="block mb-2 text-sm font-medium text-gray-900">
            Выберите набор фурнитуры:
          </label>
          <select
            id="hardwareSelect"
            value={selectedHardwareSetId}
            onChange={handleSelectHardware}
            className="border py-2 rounded-md border-slate-600 mt-4 bg-emerald-100">
            <option value="">Выберите фурнитуру</option>
            {selectedCabin.hardware_sets.map((set) => (
              <option key={set.set_id} value={set.set_id}>
                {set.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {selectedCabinId && (
        <div className="mt-4">
          <label htmlFor="cabinHeight" className="block mb-2 text-sm font-medium text-gray-900">
            Высота кабины (мм):
          </label>
          <input
            type="number"
            id="cabinHeight"
            value={height}
            onChange={handleChangeHeight}
            className="border border-slate-600 rounded-md ml-3"
          />
        </div>
      )}
      {selectedCabin && (
        <div className="mt-4">
          <h3 className="text-lg font-medium">Стороны кабины (мм):</h3>
          {dimensions.map((dim, index) => (
            <div key={index} className="mb-2 mt-2">
              <label
                htmlFor={`dimension-${index}`}
                className="block  text-sm font-normal text-gray-900">
                {dim.title}:
              </label>
              <input
                type="number"
                id={`dimension-${index}`}
                value={dim.dimension}
                onChange={(e) => handleChangeDimension(index, e.target.value)}
                className="border border-slate-600 rounded-md ml-3"
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-row items-center justify-center mt-4 pb-6">
        <div>
          <p className="pr-2">Цена:</p>
        </div>
        <div>
          <p className="font-semibold text-2xl text-blue-600 pr-2">
            {finalPrice.toLocaleString('ru-RU', { useGrouping: true, maximumFractionDigits: 0 })}
          </p>
        </div>
        <div>
          <p> руб.</p>
        </div>
      </div>
      <div className="mt-3 flex justify-center">
        <button
          className="flex items-center mt-6 font-normal text-xl bg-slate-300 text-slate-800 active:bg-slate-600   px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={calculate}>
          <span>РАСЧЕТ</span>
          <MdOutlineCalculate className="ml-1" />
        </button>
      </div>

      {hasPhotos && (
        <div className="mt-3 flex justify-center">
          <button
            className="flex items-center bg-slate-300 text-slate-800 active:bg-slate-600 text-lg px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            onClick={showPhoto}>
            <span>Фото образцов</span> <PiGooglePhotosLogoDuotone className="ml-2" />
          </button>
        </div>
      )}
      {displayPhotos && (
        <div className="photo-gallery mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {selectedPhotos.map((photo) => (
            <div key={photo.photo_id} className="photo-item cursor-pointer">
              <img
                src={`${process.env.REACT_APP_URL}/${photo.photo_url}`}
                alt="Фото"
                className="w-full max-w-[300px] mx-auto"
                onClick={() => openImageModal(`${process.env.REACT_APP_URL}/${photo.photo_url}`)}
              />
            </div>
          ))}
        </div>
      )}

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="modal-content">
            <img src={selectedImage} alt="Увеличенное фото" className="max-w-full max-h-full" />
            <button
              className="absolute top-0 right-0 m-4 text-white text-2xl"
              onClick={closeImageModal}>
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MainPage;
