import { configureStore } from '@reduxjs/toolkit';
import mainReducer from './slice/mainSlice'; // Импортируйте ваш slice
import { reducer as cabinPhotosReducer } from './slice/cabinPhotosSlice'; // Импорт редьюсера фотографий кабин


import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['someReducer'] // if you want to persist only specific reducers
};

const persistedMainReducer = persistReducer({ ...persistConfig, key: 'main' }, mainReducer);
const persistedCabinPhotosReducer = persistReducer({ ...persistConfig, key: 'cabinPhotos' }, cabinPhotosReducer);

export const store = configureStore({
    reducer: {
        main: persistedMainReducer,
        cabinPhotos: persistedCabinPhotosReducer, // Добавление редьюсера фотографий кабин
    },
});

export const persistor = persistStore(store);
