import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Определение асинхронного действия для загрузки фотографий
export const fetchCabinPhotos = createAsyncThunk(
    'cabinPhotos/fetchCabinPhotos',
    async ({userId}, { rejectWithValue }) => {
      try {
        console.log('👕👕👕👕👕');

        // Пример URL. Замените его на фактический URL вашего API.
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/getPhotoSamples`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.toString());
      }
    }
  );

const cabinPhotosSlice = createSlice({
    name: 'cabinPhotos',
    initialState: {
      photos: [],
      loading: false,
      error: null,
    },
    reducers: {
      // Тут могут быть редьюсеры для синхронных действий, если они нужны
    },
    extraReducers: {
      [fetchCabinPhotos.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [fetchCabinPhotos.fulfilled]: (state, action) => {
        console.log('🎗 fulfilled action.payload = ', action.payload);
        state.photos = action.payload;
        state.loading = false;
      },
      [fetchCabinPhotos.rejected]: (state, action) => {
        console.log('🎗 rejected');
        state.loading = false;
        state.error = action.payload;
      },
    },
  });
  
  // Экспортируем редьюсер и асинхронные действия
  export const { reducer } = cabinPhotosSlice;
  export const cabinPhotosActions = { fetchCabinPhotos };
  
  // Экспортируем селекторы, если они нужны
  export const selectCabinPhotos = (state) => state.cabinPhotos.photos;
  export const selectCabinPhotosLoading = (state) => state.cabinPhotos.loading;
  export const selectCabinPhotosError = (state) => state.cabinPhotos.error;
  