import logo from './logo.svg';
import './App.css';
import MainPage from './pages/MainPage';

function App() {
  return (
   
        <div className="App">
          <MainPage></MainPage>
        </div>
    
  );
}

export default App;
