import React, { useState } from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { AiOutlineMenuFold } from 'react-icons/ai';
//import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//import { getAuth, signOut } from 'firebase/auth';
//import { removeUser } from '../store/slice/userSlice';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const tradeMarginLS = localStorage.getItem('tradeMargin');


  const [tradeMargin, setTradeMargin] = useState(() => {
    // Получаем сохраненное значение наценки из localStorage или устанавливаем по умолчанию ''
    const savedMargin = localStorage.getItem('tradeMargin');
    return savedMargin || '';
  });

  /*   const handleLogOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    dispatch(removeUser());
  }; */

  const [isMarginModalOpen, setIsMarginModalOpen] = useState(false);

  const openMarginModal = () => {
    setIsMarginModalOpen(true);
  };

  const closeMarginModal = () => {
    setIsMarginModalOpen(false);
  };

  const handleTradeMarginChange = (event) => {
    setTradeMargin(event.target.value);
  };

  const handleTradeMarginSubmit = () => {
    // Сохраняем торговую наценку в localStorage
    localStorage.setItem('tradeMargin', tradeMargin);
    console.log('Торговая наценка:', tradeMargin);
    // Здесь можно отправить наценку на сервер или обработать каким-то другим образом
    closeMarginModal();
  };

  return (
    <>
    <nav className="bg-slate-800 text-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start items-center lg:w-0 lg:flex-1">
            <img className="h-8 w-auto sm:h-10" src="logo.png" alt="Glarioni" />
            <p className="pl-2">Glarioni</p><p className='pl-2 text-slate-600'>{tradeMarginLS}</p>

          </div>
          {/* Mobile menu button */}
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className=" inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
              onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <AiOutlineMenuFold className="h-6 w-6 text-slate-400" />
                ) : (
                  <HiOutlineMenu className="h-6 w-6 text-slate-400" />
              )}
            </button>
          </div>
          {/* Links section */}

          <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
        <button onClick={openMarginModal} className=''>Настройки</button>
      </div>
      <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
        <button >Выход</button>
      </div>


          
        </div>
      </div>
      {/* Mobile menu, show/hide based on mobile menu state. */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 ">
          <button onClick={openMarginModal} className='pr-4'>Настройки</button>
          <button>Выход</button>
        </div>
      </div>
      </div>
    </nav>
    {isMarginModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Торговая наценка</h4>
            </div>
            <div className="modal-body">
              <input
              className='border border-slate-600 rounded-md ml-3'
                type="number"
                value={tradeMargin}
                onChange={handleTradeMarginChange}
              />
            </div>
            <div className="modal-footer">
              <button 
              className='mt-1  bg-slate-300 text-slate-800 active:bg-slate-600 font-semibold text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
              onClick={closeMarginModal}>Отмена</button>
              <button 
              className='mt-1 bg-blue-400 text-slate-800 active:bg-slate-600 font-semibold text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
              onClick={handleTradeMarginSubmit}>Ок</button>
            </div>
          </div>
        </div>
      )}
                  </>
    

  );
};

export default Navbar;
